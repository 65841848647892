
.context {
    padding-top: 3px;
    padding-bottom: 3px;
}
.user-context {
    float: right;
    text-align: right;
    font-size: smaller;
}

.square-box{
    position: relative;
    overflow: hidden;
/*
    min-width: 315px;
*/
}
.square-box:before{
    content: "";
    display: block;
    padding-top: 100%;
    margin-bottom: 20px;
}
.square-content{
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto; 
}

@supports (-webkit-overflow-scrolling: touch) { /* iOS only */
    .square-content {
        overflow: hidden; 
    }
}

@media (max-width: 767px) {
    .oversigt .col-xs-12, .oversigt .col-xs-6 , .oversigt .col-xs-4 {
        padding-right: 8px !important;
        padding-left: 8px !important;
    }
    .oversigt-tile .panel-footer {
        padding-left: 5px;
        padding-right: 5px;
    }
    .forberedelse-tile .panel-footer {
        padding: 5px 3px;
    }
    .forberedelse-tile .panel-footer {
        line-height: 1;
    }
}

@media (min-width: 992px) {
    .container {
        width: 990px !important;
    }
    .oversigt .col-xs-12, .oversigt .col-xs-6 , .oversigt .col-xs-4 {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
}

@media (min-width: 1024px) {
    .oversigt .col-xs-12, .oversigt .col-xs-6 , .oversigt .col-xs-4 {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }
    .container {
        width: 990px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1120px;
    }
}


.kompakt-oversigt-tile {
    background-color: #1E677B;
}

.kompakt-oversigt-tile .panel-heading {
    padding: 5px 3px;
    background-color: rgba(125, 211, 247, 1);
    color: white;
    border-bottom: none;
}

.kompakt-oversigt-tile .panel-body {
    padding: 0px 8px 5px;
    background-color: #1E677B;
    color: white;
    text-align: right;
}

.kompakt-oversigt-tile .panel-footer {
    padding: 0px 8px 5px;
    background-color: #1E677B;
    color: white;
    border-top: none;
    text-align: right;
}

.oversigt-tile .panel-body {
    position: relative;
}

.square-box .oversigt-tile {
    display: flex;
    flex-direction: column;
}
.square-box .oversigt-tile .panel-body {
    flex-grow: 1;
}

.oversigt-tile .panel-footer {
    width: 100%;
    background-color: #1E677B;
    color: white;
    text-align: center;
    border-top: none;
}

.oversigt-tile .panel-heading {
    width: 100%;
    background-color: rgba(125, 211, 247, 1);
    color: white;
    padding: 8px 15px;
    text-align: center;
}

.oversigt-tile .panel-heading > a {
    width: 100%;
    background-color: rgba(125, 211, 247, 1);
    color: white;
    padding: 0;
}

.oversigt-tile > .panel-footer > a:hover {
    text-decoration: none;
    color: white;
}

.oversigt-tile .panel-body .info-link {
    color: black;
    position: absolute;
    top: 4px;
    right: 5px;
}

.kompakt-oversigt-tile .info-link {
    padding: 0;
    right: 5px;
}

.afgiv-helbred-1 {
    background-image: url("/Resources/images/Letpension/Om_Letpension_322x982.png");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.afgiv-helbred-2 {
    background-image: url("/Resources/images/Letpension/Om_Letpension_322x487.png");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.afgiv-helbred-3 {
    background-image: url("/Resources/images/Letpension/Om_Letpension_457x300.jpg");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.aflever-pensionsinfo-1 {
    background-image: url("/Resources/images/Letpension/Tjek_din_pension_322x982.png");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.aflever-pensionsinfo-2 {
    background-image: url("/Resources/images/Letpension/Tjek_din_pension_322x487.png");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.aflever-pensionsinfo-3 {
    background-image: url("/Resources/images/Letpension/Tjek_din_pension_457x300.jpg");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.angiv-vaerdier-1 {
    background-image: url("/Resources/images/Letpension/Omkostninger_322x982.png");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.angiv-vaerdier-2 {
    background-image: url("/Resources/images/Letpension/Omkostninger_322x487.png");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.angiv-vaerdier-3 {
    background-image: url("/Resources/images/Letpension/Omkostninger_457x300.jpg");
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}

.footer-navlink {
    padding: 1px;
}

.footer-navlink-header {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    padding-bottom: 5px;
    padding-top: 10px;
    text-decoration: none;
}


.dark .navbar-default .navbar-toggle {
  border-color: rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.4);
  margin-left: 5px;
}

  .with-dropdown-buttons .header-dropdown-buttons {
    position: absolute;
    top: 4px;
    right: 0px;
  }

.header-dropdown-buttons .btn-group > .btn {
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  border-radius: 5%;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 38px;
  text-align: center;
  background-color: #f2f2f2;
  border: 1px solid #e9e9e9;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0;
}

  .fixed-header-on .header:not(.centered) .main-navigation.with-dropdown-buttons .header-dropdown-buttons {
    top: 3px;
  }

 .fixed-header-on .header .logo img {
  max-height: 50px;
}

 .header-gradient {
    background-color:  rgba(125, 211, 247, 1);
    height: 1px;
}

.overlay-bottom {
  position: absolute;
  top: auto;
  padding: 5px;
  bottom: 0px;
  left: 0;
  right: 0px;
  background-color: rgba(30, 103, 123, 0.8);
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all ease-in-out 0.25s;
  -o-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
  height: 0;
  color: #ffffff;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.overlay-visible .overlay-bottom {
  opacity: 1;
  filter: alpha(opacity=100);
  height: auto !important;
  padding-bottom: 5px;
}


.login-btn-fixed-width {
  width: 150px;
}

.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: left;
}

.pension-slider {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.pension-slider span {
    font-weight: bold;
    font-size: 160%;
    margin-right: 10px;
}

.center-vertical-and-horisontal {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.center-vertical {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

tr .table-colored > thead > tr > th {
    background-color: #2aa4a5;
}

tr .table-colored {
    border-bottom-color: #2aa4a5;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
    margin-top: 12px;
}

.mt-30 {
  margin-top: 30px;
}
.pl-100 {
  padding-left: 90px;
}

.lp.fa-exclamation-triangle {
    color: #f26d21;
    font-size: small;
    
}

.LpNavBar {
    margin-top: 10px;
    margin-bottom: 0px;
}

.LpNavBarTopSpacer {
    margin-top: 0px;
}

.LpNavItem {
    padding-top: 0px;
    margin-bottom: 10px;   
    font-size: 16px; 
}

.LpNavItem:hover {
    background-color: rgba(125, 211, 247, 1);
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: rgba(125, 211, 247, 1);
}

.navbar-inverse .navbar-toggle {
    margin-top: 10px;
    margin-bottom: 10px;
}


.navbar-inverse .navbar-toggle {
    background-color: #333;
}


.dropdown > a:before {
    content: none;
}

.doklink:hover {
    cursor: pointer;
}

.logo {
    position: relative;
    padding-right: 50px;
    margin-top: 10px;
}

.logo img {
    height: 40px;
}


.piHeaderLogo {
    position: relative;
    height: 70px;
    width: 150px;
}

.piHeaderLogo img {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
}

.piDropdownHeaderNavn {
    padding-top: 10px;
    padding-right: 20px;
    color: white;
    position: relative;
    z-index: 9999;
}

.piHeaderNavn {
    padding-top: 10px;
    padding-right: 20px;
}

.piHeaderLink {
    text-decoration: none;
    color: #9d9d9d;
}

.vaelgPiPanel {
    margin: 15px;
}

#vaelgPiX {
    padding: 0;
}

.piDropdownHeaderNavn > ul {
    background-color: #dc143c;
}

.collapse-style-2 .panel-heading a {
  color: #ffffff;
  background-color: #1E677B;
}

/* Letpension Harlekin Activity Indicator animation */
.animate-pulse-1 {
    animation-name: animate-pulse;
    animation-duration: 5s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
}

.animate-pulse-2 {
    animation-name: animate-pulse;
    animation-duration: 5s;
    animation-delay: 1500ms;
    animation-iteration-count: infinite;
}
.animate-pulse-3 {
    animation-name: animate-pulse;
    animation-duration: 5s;
    animation-delay: 3000ms;
    animation-iteration-count: infinite;
}

@keyframes animate-pulse {
    0% {opacity: 1.0} 
    10% {opacity: 0.0}
    20% {opacity: 1.0}
    100% {opacity: 1.0}
}

.activityContainer {
    width: 100%;
    height: auto;
    text-align: center;
}

.activitySVG {
    vertical-align: middle; display: inline-block;
}

.activityLabel {
    display: inline-block;
    vertical-align: center;
    height: 32px;
    line-height: 39px; 
    vertical-align: middle;
    padding-left: 10px;
}

/*Old Activity indicator --- DELETE WHEN NEW Harlekin Activity Indicator works*/
.activity-indicator {
	width: 100%;
	height: 100%;
	min-height: 50px;
	position: relative;
}
.activity-indicator * {
	box-sizing: border-box
}

.activity-indicator > div {
	left: 50%;
	top: 50%;
	width: auto;
	display: inline-block;
	position: absolute;
	transform: translate(-50%, -50%);
}

.activity-indicator > div > div {
	transition-duration: 0.4s;
}

.table-aftaler {
    font-size: small;
}

.collapse-style-1 .panel-heading {
    background-color: #1E677B;
}

.collapse-style-1 .panel-heading a:not(.collapsed), .collapse-style-1 .panel-heading a {
    color: white;
}

.form-group > .DayPickerInput {
    display: block;
    position: relative;
}

.form-group > .DayPickerInput > .form-control {
    padding-right: 40px;
}

.form-group > .DayPickerInput:after {
    font-family: "FontAwesome";
    content: "\F073";
    position: absolute;
    color: #3BA5A9;
    right: 20px;
    font-size: 14px;
    font-weight: 300;
    top: 50%;
    line-height: 1;
    margin-top: -7px;
    pointer-events: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #3BA5A9 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #3BA5A9 !important;
}

.padding-clear {
    padding: 0 !important;
}

.color-attention {
    color: #dc143c !important;
}

.mineaftaler-oversigt-tile {
    min-height: 172px;
    border: 1px solid rgba(125, 211, 247, 1);
    background-color: white;
    padding: 5px;
}

.mineaftaler-oversigt-tile-title {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 28px;
    padding: 5px;
    background-color: rgba(125, 211, 247, 1);
    color: white;
}

.btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default.focus {
    background-color: #d4d4d4;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default {
    background-color: #d4d4d4;
    border-color: #d4d4d4;
}
.aftale-oversigt {
    font-size: small;
}

.situationMiniGrafHeader {
    font-size: small;
    padding: 10px 15px;
}

.betaling-detaljer .list-group {
    margin-bottom: 0;
}

.table-striped.table > tbody > tr.sum-row > td {
    border-color: #1e677b;
    font-weight: bold;
}

.ellipsis-loading:after {
    overflow: hidden;
    display: inline-block;
    content: "\22C5\22C5\22C5"; 
    width: 0;
    animation: ellipsis steps(4, end) 1500ms infinite;
}
.mineoplysninger-red {
    color: #82222F;
}
.mineoplysninger-green {
    color: #556C24;
}
.mineoplysninger-procent {
    font-size: 300%;
    text-align: center;
    font-weightX: bold;
    line-height: 1.2;
}
.mineoplysninger-box {
    display: inline-block;
    width: 22px;
    text-align: center;
    vertical-align: middle;
    line-height: inherit;
}

.mineoplysninger-subtitle {
    color: #d4d4d4;
    font-size: 14px;

}

.kundeOplysningerStatus-bemaerkning {
    color: #dc143c;
    font-size: 12px;
    margin-bottom: 3px;
}

.mineoplysninger-link {
    display: flex;
    padding: 5px;

}

.mineoplysninger-griditemtext {
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.link-black {
    color: black;
}



@keyframes ellipsis {
    to {
        width: 1.2em;    
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.2em;    
    }
}

.minetilbudAlert {
    background-color: #d4d4d4;
}

/* Aftaledetaljer */
.aftaleDetaljer .panel {
    font-size: small;
}

.aftaleDetaljer p {
    font-size: small;
}

.aftaleDetaljer .panel-heading {
    font-weight: bold;
}

.aftaleDetaljer td {
    padding-bottom: 8px;
}

.aftaleDetaljer .navn {
    vertical-align: top;
}

.aftaleDetaljer .vaerdi {
    vertical-align: bottom;
    padding-left: 16px;
}

.aftaleDetaljer .hjaelpBoble {
    float: right;
    font-size: 32px;
}

.aftaleDetaljer .hjaelpBobleSmall {
    float: right;
    font-size: 24px;
}

.aftaleDetaljer .panel-collapse .panel-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.aftaleDetaljer tbody {
    background-color: transparent;
}

#aendredeDaekningerGroup-heading-1.panel-heading {
    padding: 0;
}

#aendredeDaekningerGroup-heading-1 > h4 {
    font-size: 0.9em;
}

.sygdomOversigt_title {
    margin-top: 4px;
    text-align: center;
    font-weight: bold;
}

.sygdomOversigt_subtitle {
    font-family: Arial;
    font-size: 12px;
    color: #848484;
    word-wrap: normal;
    white-space: normal;
    display: block;
    height: 14px;
    margin-top: -3px;
    line-height: 1.4;
    text-align: center;
}

.svgRootStyle {
    background-color: transparent;
}

.sygdomOversigt_textDescription{
    word-wrap: normal;
    white-space: normal;
    height: 50px;
    text-align: center;
    margin-top: -5px;
    font-size: 12px;
}

.daekningOverblik {
    padding-top: 10px;
}
.daekningOverblik .panel-heading {
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;

}

.daekningOverblik .panel-body {
    padding-top: 5px;
    padding-bottom: 10px;

}

.behovOverblik {
    padding-top: 10px;
}
.behovOverblik .panel-heading {
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.behovOverblik .panel-body {
    padding-top: 5px;
    padding-bottom: 5px;

}

#udbetalingsTypeGroup .panel-group {
    margin-bottom: 0;
}


.oplysningStatusTabel {
    margin: 0 auto
}

/* Accordions i modal popup (InfoModal)  */
.modal-body .panel {
    margin-bottom: 10px;
}

.modal-body .panel-collapse .panel-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 0;
}

.modal-body .panel-heading a {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.modal-body .panel-heading a:after {
    right: 0;
}
/**/


.beloeb {
    white-space: nowrap;
}

/* graph overrides */

svg {
    background-color: transparent;
}

.svgGraphContainer {
    font: 12px/1.231 Arial, Helvetica, clean, sans-serif !important;
    margin-bottom: 8px;
    margin-bottom: 8px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flyover h2 {
    text-transform: none;
}

.flyover p {
    line-height: 15px;
    padding-top: 7px;
}

/**/

/* Wizard overrides */

.verticalWizard .collapse-style-1 .panel-heading .panel-title {
    font-weight: 400;
    display: inline-block;
    width: 100%;
    position: relative;
    text-decoration: none;
    padding: 10px 40px 4px 15px;
    color: white;
}

.verticalWizard .collapse-style-1 .panel-heading a {
    padding: 10px 15px 4px 15px;
}

.verticalWizard .collapse-style-1 .panel-heading a:after {
    content: none;
}

.verticalWizard .collapse-style-1 form {
    margin: 0px;
}

.horizontalWizard .nav-pills > li > a {
    color: #888;
}

.horizontalWizard .process .nav-pills > li + li:after {
    color: #888;
}

.horizontalWizard .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus, .nav-pills > li > a:hover, .nav-pills > li > a:focus {
    color: #1e677b;
    border-bottom: 1px solid #1e677b;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.btn-blue-transparent {
    color: #1E677B;
    background-color: transparent;
    border-color: #1E677B;
}

.btn-blue-transparent:hover {
    color: white;
    background-color: #1E677B;
}

.lpform-allchildren-fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
}

/* Links i hjaelpetekster */
.infoModalIndhold a {
    color: #3BA5A9;
    text-decoration: none !important;
}

.infoModalIndhold a:hover {
    color: #3BA5A9;
    text-decoration: underline !important;
}

.total-accordion a {
     cursor: text;
    font-weight: 500;
}

.total-accordion a:after {
    cursor: text;
    color: #1e677b;
}

@supports (-webkit-overflow-scrolling: touch) { /* iOS only - fix modal scrolling */
    .modal-header {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .modal-body {
        max-height: calc(100vh - 220px);
        overflow-y: auto;
    }
    .modal-footer {
        padding-top: 0;
        padding-bottom: 0;
    }
}
